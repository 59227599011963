import { BffApiUpdateItemError } from '../../../../types';
import { RefreshAccessTokenV4RequestCacheKeys } from '../../payoneerV4RequestsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { PayoneerV4RequestBffUrl } from '../../PayoneerV4RequestBffUrl';

interface RefreshAccessTokenPayoneerV4RequestsOptions {
  cacheKeys?: RefreshAccessTokenV4RequestCacheKeys;
}

function useRefreshAccessTokenPayoneerV4Requests({
  cacheKeys
}: RefreshAccessTokenPayoneerV4RequestsOptions = {}) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<unknown, unknown, BffApiUpdateItemError>({
    cacheKeys,
    url: PayoneerV4RequestBffUrl.refreshAccessToken()
  });

  return {
    refreshAccessTokenV4RequestsData: postQueryData,
    refreshAccessTokenV4RequestsError: postQueryError,
    refreshAccessTokenV4RequestsLoading: postQueryLoading,
    refreshAccessTokenV4RequestsErrorMessage: postQueryErrorMessage,
    refreshAccessTokenV4Requests: postQuery,
    refreshAccessTokenV4RequestsReset: postQueryReset
  };
}

export default useRefreshAccessTokenPayoneerV4Requests;
