import React, { ReactNode } from 'react';
import isEmpty from 'lodash/isEmpty';

import { IconsEnum } from '../../../../../assets/icons/types';
import { TeamUUID, FetchTeamsCacheKey } from '../../../teamsTypes';
import { I18nText } from '../../../../../types';

import { useToastNotification } from '../../../../../common/hooks/useToastNotification';

import {
  InviteNewUsersToCompanyForm,
  useInviteNewUsersToCompanyForm
} from '../../forms/InviteNewUsersToCompanyForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { teamsKeys } from '../../../../../locales/keys';

interface InviteNewUsersToCompanyModalButtonProps {
  companyUuid: TeamUUID;
  cacheKeys?: FetchTeamsCacheKey[];
  className?: string;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: string;
  children?: ReactNode;
}

const INVITE_NEW_USERS_TO_COMPANY_FORM = 'invite-new-users-to-company-form';

function InviteNewUsersToCompanyModalButton({
  companyUuid,
  cacheKeys,
  className,
  i18nText,
  icon,
  iconClassName,
  children
}: InviteNewUsersToCompanyModalButtonProps) {
  const { showToastI18nNotification } = useToastNotification({
    i18nMessage: teamsKeys.usersInvited,
    appearance: 'success'
  });

  const {
    control,
    watchEmails,
    resetInviteNewUsersToCompanyForm,
    createTeamInvitationsReset,
    createTeamInvitationsErrorMessage,
    createTeamInvitationsLoading,
    handleInviteNewUsersToCompany
  } = useInviteNewUsersToCompanyForm({
    companyUuid,
    cacheKeys,
    afterSubmit: showToastI18nNotification
  });

  return (
    <FormModalButton
      className={className}
      form={INVITE_NEW_USERS_TO_COMPANY_FORM}
      i18nSubmitText={teamsKeys.provideAccess}
      i18nText={i18nText}
      i18nTitle={teamsKeys.provideAccess}
      icon={icon}
      iconClassName={iconClassName}
      buttonChildren={children}
      isLoading={createTeamInvitationsLoading}
      onOpen={resetInviteNewUsersToCompanyForm}
      onSubmit={handleInviteNewUsersToCompany}
      onClose={createTeamInvitationsReset}
      submitDisabled={isEmpty(watchEmails)}
    >
      <div className="flex-1 overflow-y-auto px-2">
        <InviteNewUsersToCompanyForm
          form={INVITE_NEW_USERS_TO_COMPANY_FORM}
          control={control}
          isLoading={createTeamInvitationsLoading}
        />
        <div className="px-4">
          <AlertMessage message={createTeamInvitationsErrorMessage} />
        </div>
      </div>
    </FormModalButton>
  );
}

export default InviteNewUsersToCompanyModalButton;
