const baseUrl = 'payoneer_v4_requests';

export class PayoneerV4RequestBffUrl {
  static generateApplicationToken() {
    return `${baseUrl}/generate_application_token`;
  }

  static refreshAccessToken() {
    return `${baseUrl}/refresh_access_token`;
  }
}
