import React from 'react';
import { Controller, FieldPath } from 'react-hook-form';
import map from 'lodash/map';
import isArray from 'lodash/isArray';

import {
  TextsListFieldList,
  TextsListFieldListRequiredProps
} from './components/TextsListFieldList';

import { TextsListFieldRequiredProps } from './TextsListField.types';

interface TextsListFieldProps<T> {
  name: FieldPath<T>;
}

function TextsListField<T>({
  control,
  disabled,
  name,
  i18nPlaceholder,
  placeholder
}: TextsListFieldProps<T> &
  TextsListFieldRequiredProps<T> &
  TextsListFieldListRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextsListFieldList
          value={value as string[]}
          disabled={disabled}
          errors={isArray(error) ? map(error, 'message') : null}
          error={isArray(error) ? null : error?.message}
          i18nPlaceholder={i18nPlaceholder}
          placeholder={placeholder}
          onChange={onChange}
        />
      )}
    />
  );
}

export default TextsListField;
