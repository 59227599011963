import React, { Fragment, useCallback } from 'react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import size from 'lodash/size';

import { IconsEnum } from '../../../../../assets/icons/types';

import { TextsListFieldListRequiredProps } from './TextsListFieldList.types';

import { PureButtonHelper } from '../../../../buttons/PureButtonHelper';
import { Icon } from '../../../../Icon';
import { Translate } from '../../../../Translate';

import { TextsListFieldListInput } from '../TextsListFieldListInput';

import { words } from '../../../../../locales/keys';

interface TextsListFieldListProps {
  value: string[];
  errors?: string[];
  error?: string | null;
  onChange: (value: string[]) => void;
}

function TextsListFieldList({
  value,
  disabled,
  errors,
  error,
  i18nPlaceholder,
  onChange
}: TextsListFieldListProps & TextsListFieldListRequiredProps) {
  const handleInputChange = useCallback<
    (index: number, inputValue: string) => void
  >(
    (index, inputValue) => {
      const newValue = value ? [...value] : [];

      newValue[index] = inputValue;

      onChange(newValue);
    },
    [value, onChange]
  );

  const handleInputRemove = useCallback<(index: number) => void>(
    (index) => {
      onChange(filter(value, (item, itemIndex) => index !== itemIndex));
    },
    [value, onChange]
  );

  const handleAddItem = useCallback<() => void>(
    () => onChange([...(value || []), '']),
    [value, onChange]
  );

  return (
    <Fragment>
      <div className="mb-2 space-y-2">
        {map(value, (item, index) => (
          <TextsListFieldListInput
            key={index}
            index={index}
            value={item}
            error={errors ? errors[index] : null}
            i18nPlaceholder={i18nPlaceholder}
            disabled={disabled}
            removeDisabled={size(value) === 1}
            onChange={handleInputChange}
            onRemove={handleInputRemove}
          />
        ))}
      </div>
      {error ? (
        <p className="my-1 truncate text-red-500 text-2xs align-center">
          <Icon
            icon={IconsEnum.EXCLAMATION_CIRCLE}
            className="h-4 w-4 -mt-0.5 inline-block"
          />
          <span>
            {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
          </span>
        </p>
      ) : null}
      <div>
        <PureButtonHelper
          className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
          disabled={disabled}
          i18nText={words.addMore}
          onClick={handleAddItem}
        />
      </div>
    </Fragment>
  );
}

export default TextsListFieldList;
