import {
  TeamUUID,
  FetchTeamsCacheKey
} from '../../../../../main/teams/teamsTypes';

import { InviteNewUsersToCompanyModalButton } from '../../../../../main/teams/components/modalButtons/InviteNewUsersToCompanyModalButton';

import { Icon } from '../../../../../helpers/Icon';
import { Translate } from '../../../../../helpers/Translate';

import { IconsEnum } from '../../../../../assets/icons/types';
import { teamsKeys } from '../../../../../locales/keys';

interface MenuInviteNewUsersToCompanyModalButtonProps {
  companyUuid: TeamUUID;
  cacheKeys?: FetchTeamsCacheKey[];
}

function MenuInviteNewUsersToCompanyModalButton({
  companyUuid,
  cacheKeys
}: MenuInviteNewUsersToCompanyModalButtonProps) {
  return (
    <div className="p-4">
      <div className="relative p-2 flex items-center space-x-4 rounded-xl bg-blue-500 bg-opacity-0 hover:bg-opacity-10 focus-within:ring-base -m-2">
        <div className="shrink-0 flex items-center justify-center h-16 w-16 rounded-lg bg-blue-500">
          <Icon className="h-8 w-8 text-white" icon={IconsEnum.PLUS_OUTLINE} />
        </div>
        <div>
          <h3 className="text-sm font-medium">
            <InviteNewUsersToCompanyModalButton
              className="focus:ring-0 focus:ring-offset-0"
              companyUuid={companyUuid}
              cacheKeys={cacheKeys}
            >
              <span className="absolute inset-0" aria-hidden="true" />
              <Translate id={teamsKeys.inviteNewUser} />
              <span aria-hidden="true"> →</span>
            </InviteNewUsersToCompanyModalButton>
          </h3>
          <p className="mt-1 text-xs leading-4 text-gray-600 dark:text-gray-400">
            <Translate id={teamsKeys.addYourTeamMembersToCompany} />
          </p>
        </div>
      </div>
    </div>
  );
}

export default MenuInviteNewUsersToCompanyModalButton;
