import React, { ChangeEventHandler, useCallback } from 'react';

import { IsDisabled } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useTranslate } from '../../../../../common/hooks/useTranslate';

import { PureIconButtonHelper } from '../../../../buttons/PureIconButtonHelper';
import { Icon } from '../../../../Icon';
import { Translate } from '../../../../Translate';

import { TextsListFieldListInputRequiredProps } from './TextsListFieldListInput.types';

interface TextsListFieldListInputProps {
  name?: string;
  index: number;
  value: string;
  error?: string;
  disabled?: IsDisabled;
  removeDisabled?: IsDisabled;
  onChange: (index: number, value: string) => void;
  onRemove: (index: number) => void;
}

function TextsListFieldListInput({
  name,
  index,
  value,
  error,
  disabled,
  removeDisabled,
  i18nPlaceholder,
  placeholder,
  onChange,
  onRemove
}: TextsListFieldListInputProps & TextsListFieldListInputRequiredProps) {
  const { t } = useTranslate();

  const handleChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => onChange(index, e.target.value),
    [index, onChange]
  );

  const handleRemove = useCallback<() => void>(
    () => onRemove(index),
    [index, onRemove]
  );
  return (
    <div>
      <div className="flex gap-1 items-center">
        <div className="w-full">
          <div>
            <div className="relative">
              <textarea
                className="basic-input resize-none flex"
                placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
                name={`${name}-${index}`}
                value={value}
                rows={1}
                disabled={disabled}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <PureIconButtonHelper
          className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
          iconClassName="h-6 w-6"
          icon={IconsEnum.X_OUTLINE}
          disabled={disabled || removeDisabled}
          onClick={handleRemove}
        />
      </div>
      {error ? (
        <p className="mt-1 truncate text-red-500 text-2xs align-center">
          <Icon
            icon={IconsEnum.EXCLAMATION_CIRCLE}
            className="h-4 w-4 -mt-0.5 inline-block"
          />
          <span>
            {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
          </span>
        </p>
      ) : null}
    </div>
  );
}

export default TextsListFieldListInput;
