import {
  CreatedAt,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  DeleteItemGqlError,
  DeleteItemCacheKey,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  UpdateItemGqlError,
  UpdateItemCacheKey,
  ID,
  ItemGqlError,
  DateType,
  NanoIDFilter,
  TextFilterType,
  UUID
} from '../../types';
import { UserFullName } from '../users/usersTypes';

export type TeamInvitationID = ID;
export type TeamInvitationUUID = UUID;
export type TeamInvitationCreatedAt = CreatedAt;
export type TeamInvitationEmail = string;
export type TeamInvitationStatus = string;
export type TeamInvitationType = string;
export type TeamInvitationExpiredAt = DateType;

export type TeamInvitationUserFullName = UserFullName;

export enum TeamInvitationStatuses {
  NONE = 'none',
  SENT = 'sent',
  ACCEPTED = 'accepted',
  CANCELED = 'canceled'
}

export interface FetchTeamInvitationsFilters {
  teamNanoId?: NanoIDFilter;
  status?: TextFilterType;
}

export type FetchTeamInvitationsGqlQuery = FetchItemsGqlQuery;

export type TeamInvitationGqlError = ItemGqlError;

export type FetchTeamInvitationsCacheKey = FetchItemsCacheKey;

export type FetchTeamInvitationsFetched = FetchItemsFetched;
export type FetchTeamInvitationsErrorMessage = FetchItemsErrorMessage;
export type FetchTeamInvitationsSort = FetchItemsSort;
export type FetchTeamInvitationsSortTeamInvitations = FetchItemsSortItems;
export type FetchTeamInvitationsPage = FetchItemsPage;
export type FetchTeamInvitationsLimit = FetchItemsLimit;
export type FetchTeamInvitationsTotalCount = FetchItemsTotalCount;
export type FetchTeamInvitationsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchTeamInvitationsPaginateTeamInvitations =
  FetchItemsPaginateItems;
export type FetchTeamInvitationsPrefetchTeamInvitations =
  FetchItemsPrefetchItems;

export type FetchTeamInvitationsFiltersFunc = (
  nextFilters: FetchTeamInvitationsFilters
) => void;
export type FetchTeamInvitationsFilterTeamInvitations =
  FetchItemsFilterItems<FetchTeamInvitationsFilters>;
export type FetchTeamInvitationsClearTeamInvitationsFilters =
  FetchItemsClearItemsFilters;

export enum FetchTeamInvitationsSortableFields {
  CREATED_AT = 'createdAt',
  EMAIL = 'email'
}

export enum FetchTeamInvitationsSortTypes {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC'
}

export type DeleteTeamInvitationGqlQuery = DeleteItemGqlQuery;
export type DeleteTeamInvitationGqlStatus = DeleteItemGqlStatus;
export type DeleteTeamInvitationGqlError = DeleteItemGqlError;
export type DeleteTeamInvitationCacheKey = DeleteItemCacheKey;
export type DeleteTeamInvitationCacheKeys = DeleteTeamInvitationCacheKey[];

export type UpdateTeamInvitationGqlQuery = UpdateItemGqlQuery;
export type UpdateTeamInvitationGqlStatus = UpdateItemGqlStatus;
export type UpdateTeamInvitationGqlError = UpdateItemGqlError;
export type UpdateTeamInvitationCacheKey = UpdateItemCacheKey;
export type UpdateTeamInvitationCacheKeys = UpdateTeamInvitationCacheKey[];
