import sum from 'lodash/sum';

import { TeamInvitationExpiredAt } from '../../teamInvitationsTypes';
import { TEAM_INVITATION_EXPIRY_HOURS } from '../../teamInvitationsConstants';

function calcTeamInvitationExpiryDateFromNow(): TeamInvitationExpiredAt {
  const now = new Date();
  const expiryDate = new Date();

  expiryDate.setHours(sum([now.getHours(), TEAM_INVITATION_EXPIRY_HOURS]));

  return expiryDate.toISOString();
}

export default calcTeamInvitationExpiryDateFromNow;
