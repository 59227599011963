import {
  FetchTeamInvitationsCacheKey,
  TeamInvitationEmail,
  TeamInvitationExpiredAt
} from '../../teamInvitationsTypes';
import { TeamID, TeamUUID } from '../../../teams/teamsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface CreateTeamInvitationsOptions {
  query: string;
  cacheKeys?: FetchTeamInvitationsCacheKey[];
}

export interface CreateTeamInvitationsResponse {
  createTeamInvitations: {
    status: string;
  };
}

export interface CreateTeamInvitationsInput {
  teamId: TeamID | TeamUUID;
  emails: TeamInvitationEmail[];
  expiredAt: TeamInvitationExpiredAt;
}

export interface CreateTeamInvitationsError {
  fullMessages: string[] | null;
}

const action = 'createTeamInvitations';

function useCreateTeamInvitations({
  query,
  cacheKeys
}: CreateTeamInvitationsOptions) {
  const {
    updateQuery,
    updateQueryData,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading,
    updateQueryReset
  } = useUpdateQuery<
    CreateTeamInvitationsInput,
    CreateTeamInvitationsResponse,
    CreateTeamInvitationsError,
    unknown
  >({ action, cacheKeys, query });

  return {
    createTeamInvitations: updateQuery,
    createTeamInvitationsData: updateQueryData,
    createTeamInvitationsError: updateQueryError,
    createTeamInvitationsErrorMessage: updateQueryErrorMessage,
    createTeamInvitationsLoading: updateQueryLoading,
    createTeamInvitationsReset: updateQueryReset
  };
}

export default useCreateTeamInvitations;
