import * as yup from 'yup';
import trim from 'lodash/trim';

import { isEmail } from '../../../../../../../utils/isEmail';

import { formsErrors } from '../../../../../../../locales/keys';

export const inviteNewUsersToCompanyFormValidationSchema = yup.object().shape({
  emails: yup
    .array()
    .of(
      yup
        .string()
        .required(formsErrors.email.required)
        .test('isValidEmail', formsErrors.email.valid, (value) =>
          isEmail(trim(value))
        )
    )
    .min(1, formsErrors.required)
});
