import React, { useCallback } from 'react';

import { ClassName, I18nText, UpdateItemCacheKeys } from '../../../../../types';

import { useRefreshAccessTokenPayoneerV4Requests } from '../../../hooks/useRefreshAccessTokenPayoneerV4Requests';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useToastNotification } from '../../../../../common/hooks/useToastNotification';

import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { words } from '../../../../../locales/keys';

interface RefreshAccessTokenPayoneerV4RequestsButtonProps {
  cacheKeys?: UpdateItemCacheKeys;
  i18nText?: I18nText;
  className?: ClassName;
}

function RefreshAccessTokenPayoneerV4RequestsButton({
  cacheKeys,
  i18nText,
  className
}: RefreshAccessTokenPayoneerV4RequestsButtonProps) {
  const {
    refreshAccessTokenV4RequestsLoading,
    refreshAccessTokenV4RequestsErrorMessage,
    refreshAccessTokenV4Requests
  } = useRefreshAccessTokenPayoneerV4Requests({
    cacheKeys
  });

  useShowToastOnErrorChange({
    error: refreshAccessTokenV4RequestsErrorMessage
  });

  const { showToastI18nNotification: showSuccessNotification } =
    useToastNotification({
      appearance: 'success',
      i18nMessage: words.success
    });

  const handelRecalculateBalancesCompany = useCallback<() => Promise<void>>(
    () =>
      refreshAccessTokenV4Requests({}).then(() => {
        showSuccessNotification();
      }),
    [showSuccessNotification, refreshAccessTokenV4Requests]
  );

  return (
    <PureButtonHelper
      className={className}
      disabled={refreshAccessTokenV4RequestsLoading}
      i18nText={i18nText}
      onClick={handelRecalculateBalancesCompany}
    />
  );
}

export default RefreshAccessTokenPayoneerV4RequestsButton;
