import React, { ReactNode } from 'react';
import compact from 'lodash/compact';

import { ClassName } from '../../../../types';
import { TeamNanoID } from '../../teamsTypes';

import { useTranslate } from '../../../../common/hooks/useTranslate';
import { useTeamShowPage } from '../../hooks/useTeamShowPage';

import { ShowItemLayout } from '../../../common/layouts/ShowItemLayout';

import { TeamProfileSecondaryMenu } from '../../components/menus/TeamProfileSecondaryMenu';

import { AlertMessage } from '../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../helpers/LoadingSkeleton';

import {
  itemTypesKeys,
  teamsKeys,
  revenueShareItemsKeys
} from '../../../../locales/keys';

import { TeamsPermissions, TeamTabs } from '../../teamsConstants';

interface TeamShowLayoutProps {
  action: TeamsPermissions;
  active?: TeamTabs;
  teamNanoId: TeamNanoID;
  mainAddClassName?: ClassName;
  wrapperClassName?: ClassName;
  children: ReactNode;
  header?: ReactNode;
  headerWrapClassName?: ClassName;
  sidebar?: ReactNode;
}

export function titleI18nSuffixFromTab(active: TeamTabs) {
  switch (active) {
    case TeamTabs.ARTISTS:
      return teamsKeys.performersTeam;
    case TeamTabs.ITEM_TYPES:
      return itemTypesKeys.plural;
    case TeamTabs.MEMBERS:
      return teamsKeys.companyUsers;
    case TeamTabs.PROFILE:
      return teamsKeys.info;
    case TeamTabs.REVENUE_SHARE:
      return revenueShareItemsKeys.single;
    case TeamTabs.FILES:
      return teamsKeys.files;
    case TeamTabs.RECORDS:
      return teamsKeys.records;
    case TeamTabs.REPORTS:
      return teamsKeys.reports;
    case TeamTabs.SUBBOOKS:
      return teamsKeys.subbooks;
    case TeamTabs.AUTO_CHECK_IN:
      return teamsKeys.autoCheckInRule;
    case TeamTabs.SC_TEMPLATE:
      return teamsKeys.scTemplates;
    case TeamTabs.EVENT_DISPATCHER:
      return teamsKeys.eventDispatcher;
  }
}

function TeamShowLayout({
  action,
  active,
  teamNanoId,
  mainAddClassName,
  wrapperClassName,
  children,
  header,
  headerWrapClassName,
  sidebar
}: TeamShowLayoutProps) {
  const {
    team,
    teamFetched,
    teamErrorData,
    teamError,
    teamIsPlaceholderData,
    isSelf,
    isSelfLoading,
    isWorker
  } = useTeamShowPage({ teamNanoId });

  const { t } = useTranslate();

  const titleI18nSuffix = titleI18nSuffixFromTab(active);

  return (
    <ShowItemLayout
      withoutSecondaryMenu={!active}
      customSecondaryMenu={
        <TeamProfileSecondaryMenu
          isSelf={isSelf}
          isWorker={isWorker}
          team={team}
          teamFetched={teamFetched}
          teamIsPlaceholderData={teamIsPlaceholderData}
          active={active}
        />
      }
      mainAddClassName={mainAddClassName}
      action={action}
      withoutActionError={isSelfLoading}
      error={teamErrorData}
      header={header}
      headerWrapClassName={headerWrapClassName}
      title={
        team
          ? compact<string>([
              team.name,
              titleI18nSuffix ? t(titleI18nSuffix) : null
            ]).join(' - ')
          : `${t(teamsKeys.singular)} #${teamNanoId}`
      }
      sidebar={sidebar}
    >
      <div className={wrapperClassName}>
        <AlertMessage addClassName="m-4" message={teamError} />
        <LoadingSkeleton addClass="absolute inset-0" loaded={teamFetched}>
          {children}
        </LoadingSkeleton>
      </div>
    </ShowItemLayout>
  );
}

export default TeamShowLayout;
