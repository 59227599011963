import { Control } from 'react-hook-form';

import { IsLoading } from '../../../../../types';

import {
  InviteNewUsersToCompanyFormData,
  InviteNewUsersToCompanyFormFields
} from './InviteNewUsersToCompanyForm.types';

import { TextsListField } from '../../../../../helpers/FormFields/TextsListField';

import { Form } from '../../../../../helpers/Form';
import { Translate } from '../../../../../helpers/Translate';

import { teamsKeys, words } from '../../../../../locales/keys';

interface InviteNewUsersToCompanyFormProps {
  form: string;
  control: Control<InviteNewUsersToCompanyFormData>;
  isLoading?: IsLoading;
}

function InviteNewUsersToCompanyForm({
  form,
  control,
  isLoading
}: InviteNewUsersToCompanyFormProps) {
  return (
    <Form id={form}>
      <div className="px-4">
        <div className="my-2">
          <Translate id={teamsKeys.enterTheEmailAddressOfTheUser} />
        </div>

        <div className="w-full mb-5">
          <TextsListField<InviteNewUsersToCompanyFormData>
            control={control}
            name={InviteNewUsersToCompanyFormFields.EMAILS}
            i18nPlaceholder={words.emailAddress}
            disabled={isLoading}
          />
        </div>
      </div>
    </Form>
  );
}

export default InviteNewUsersToCompanyForm;
