import { FetchTeamInvitationsSortTypes } from './teamInvitationsTypes';

export const INITIAL_TEAM_INVITATIONS_FILTERS = {};
export const INITIAL_TEAM_INVITATIONS_PAGE = 1;
export const INITIAL_TEAM_INVITATIONS_SORT = [
  FetchTeamInvitationsSortTypes.CREATED_AT_DESC
];
export const INITIAL_TEAM_INVITATIONS_LIMIT = 50;

export const TEAM_INVITATION_EXPIRY_HOURS = 168;

export const enum TeamInvitationsPermissions {
  READ_TEAM_INVITATION_CANCEL_BUTTON = 'read_team_invitation_cancel_button'
}
