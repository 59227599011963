import { gql } from 'graphql-request';

export const CREATE_TEAM_INVITATIONS_QUERY = gql`
  mutation CreateTeamInvitations(
    $teamId: ID!
    $emails: [String!]!
    $expiredAt: DateTime
  ) {
    createTeamInvitations(
      input: { teamId: $teamId, emails: $emails, expiredAt: $expiredAt }
    ) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
