import { gql } from 'graphql-request';

import {
  UserUUID,
  UserNanoID,
  UserID,
  UserClient,
  UserWorkExperience,
  UserCurrentTeamNanoID,
  UserBlocked
} from '../usersTypes';

export interface FetchUserDashboardQueryResponse {
  id: UserID;
  nanoId: UserNanoID;
  uuid: UserUUID;
  blocked: UserBlocked;
  client: UserClient;
  workExperience: UserWorkExperience;
  currentTeam: {
    nanoId: UserCurrentTeamNanoID;
  };
}

export const FETCH_USER_DASHBOARD_QUERY = gql`
  query UserDashboard($uuid: ID!) {
    user(uuid: $uuid) {
      id
      nanoId
      uuid
      blocked
      client
      workExperience
      currentTeam {
        nanoId
      }
    }
  }
`;
