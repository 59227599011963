import React from 'react';

import { TeamNanoID } from '../../teamsTypes';

import { useTeamShowPage } from '../../hooks/useTeamShowPage';
import { useTeamNanoIdWithCurrent } from '../../hooks/useTeamNanoIdWithCurrent';

import { TeamDashboardIndexPage } from '../../components/content/TeamDashboardIndexPage';

import { TeamShowLayout } from '../../layouts/TeamShowLayout';

import { NoResults } from '../../../../helpers/NoResults';

import { TeamTabs, TeamsPermissions } from '../../teamsConstants';
import { teamsKeys } from '../../../../locales/keys';

interface TeamDashboardPageProps {
  teamNanoId?: TeamNanoID;
}

function TeamDashboardPage({
  teamNanoId: providedTeamNanoId
}: TeamDashboardPageProps) {
  const { teamNanoId } = useTeamNanoIdWithCurrent(providedTeamNanoId);

  const { team, isSelf } = useTeamShowPage({ teamNanoId });

  return (
    <TeamShowLayout
      action={
        isSelf
          ? TeamsPermissions.READ_SELF_COMPANY_DASHBOARD_PAGE
          : TeamsPermissions.READ_OTHER_COMPANY_DASHBOARD_PAGE
      }
      active={isSelf ? undefined : TeamTabs.DASHBOARD}
      teamNanoId={teamNanoId}
      wrapperClassName="px-6 py-8 bg-gray-50 dark:bg-transparent min-h-full"
    >
      {team?.owner?.nanoId ? (
        <TeamDashboardIndexPage
          ownerNanoId={team.owner.nanoId}
          teamNanoId={teamNanoId}
        />
      ) : (
        <NoResults noResultsI18nText={teamsKeys.companyHasNoOwner} />
      )}
    </TeamShowLayout>
  );
}

export default TeamDashboardPage;
