import { BffApiUpdateItemError } from '../../../../types';
import {
  PayoneerV4RequestProgramKeys,
  GenerateApplicationTokenV4RequestCacheKeys
} from '../../payoneerV4RequestsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { PayoneerV4RequestBffUrl } from '../../PayoneerV4RequestBffUrl';

interface GenerateApplicationTokenPayoneerV4RequestsOptions {
  cacheKeys?: GenerateApplicationTokenV4RequestCacheKeys;
}

type GenerateApplicationTokenPayoneerV4RequestsInputData = {
  programKey: PayoneerV4RequestProgramKeys;
};

function useGenerateApplicationTokenPayoneerV4Requests({
  cacheKeys
}: GenerateApplicationTokenPayoneerV4RequestsOptions = {}) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    GenerateApplicationTokenPayoneerV4RequestsInputData,
    unknown,
    BffApiUpdateItemError
  >({ cacheKeys, url: PayoneerV4RequestBffUrl.generateApplicationToken() });

  return {
    generateApplicationTokenV4RequestsData: postQueryData,
    generateApplicationTokenV4RequestsError: postQueryError,
    generateApplicationTokenV4RequestsLoading: postQueryLoading,
    generateApplicationTokenV4RequestsErrorMessage: postQueryErrorMessage,
    generateApplicationTokenV4Requests: postQuery,
    generateApplicationTokenV4RequestsReset: postQueryReset
  };
}

export default useGenerateApplicationTokenPayoneerV4Requests;
