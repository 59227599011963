import { TeamNanoID } from '../../teamsTypes';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';

function useTeamNanoIdWithCurrent(providedTeamNanoId?: TeamNanoID) {
  const currentUser = useCurrentUser();

  const teamNanoId = providedTeamNanoId
    ? providedTeamNanoId
    : currentUser.currentTeam?.nanoId;

  return {
    teamNanoId
  };
}

export default useTeamNanoIdWithCurrent;
