import React, { useCallback } from 'react';

import { PayoneerV4RequestProgramKeys } from '../../../payoneerV4RequestsTypes';
import { ClassName, I18nText, UpdateItemCacheKeys } from '../../../../../types';

import { useGenerateApplicationTokenPayoneerV4Requests } from '../../../hooks/useGenerateApplicationTokenPayoneerV4Requests';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useToastNotification } from '../../../../../common/hooks/useToastNotification';

import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { words } from '../../../../../locales/keys';

interface GenerateApplicationTokenPayoneerV4RequestsButtonProps {
  programKey: PayoneerV4RequestProgramKeys;
  cacheKeys?: UpdateItemCacheKeys;
  i18nText?: I18nText;
  className?: ClassName;
}

function GenerateApplicationTokenPayoneerV4RequestsButton({
  programKey,
  cacheKeys,
  i18nText,
  className
}: GenerateApplicationTokenPayoneerV4RequestsButtonProps) {
  const {
    generateApplicationTokenV4RequestsLoading,
    generateApplicationTokenV4RequestsErrorMessage,
    generateApplicationTokenV4Requests
  } = useGenerateApplicationTokenPayoneerV4Requests({
    cacheKeys
  });

  useShowToastOnErrorChange({
    error: generateApplicationTokenV4RequestsErrorMessage
  });

  const { showToastI18nNotification: showSuccessNotification } =
    useToastNotification({
      appearance: 'success',
      i18nMessage: words.success
    });

  const handelRecalculateBalancesCompany = useCallback<() => Promise<void>>(
    () =>
      generateApplicationTokenV4Requests({ programKey }).then(() => {
        showSuccessNotification();
      }),
    [programKey, showSuccessNotification, generateApplicationTokenV4Requests]
  );

  return (
    <PureButtonHelper
      className={className}
      disabled={generateApplicationTokenV4RequestsLoading}
      i18nText={i18nText}
      onClick={handelRecalculateBalancesCompany}
    />
  );
}

export default GenerateApplicationTokenPayoneerV4RequestsButton;
